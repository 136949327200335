<template>
  <div class="ResourceCard">
    <SanityImageBackground v-if="content.image"
      class="image"
      :asset="content.image.asset"
    />
    <div class="text">
      <h3 class="title">{{ content.title }}</h3>
      <p class="intro">{{ content.text }}</p>
      <CtaLink v-if="content.cta" v-bind="content.cta" class="cta" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style scoped>
.ResourceCard {
  display: block;
  border: thin solid #eee;
  border-radius: 4px;
  overflow: hidden;
}
.image {
  padding-top: 55%;
}
.text {
  padding: var(--spacing-6) var(--spacing-5);
}
.title, .intro {
  /* These rules are required for -webkit-line-clamp */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow-y: hidden;
}
.title {
  font-weight: 500;
  font-size: var(--font-size-2);
  color: var(--text-color-black);
  line-height: 1.5;
  -webkit-line-clamp: 2;
}
.intro {
  margin-top: var(--spacing-1);
  font-weight: 400;
  font-size: var(--font-size-1);
  line-height: 1.5;
  color: var(--text-color-dark);
  -webkit-line-clamp: 3;
}
.CtaLink.cta {
  margin-top: var(--spacing-5);
  font-size: var(--font-size-1);
  line-height: 1;
}
</style>
