<template>
  <Container tagName="aside" class="GuidebookShowcase">
    <div class="panelAppearance panelLayout">
      <div class="panelItem blurb">
        <h3 class="title">{{ content.title }}</h3>
        <p class="subtitle">{{ content.subtitle }}</p>
      </div>
      <div v-for="guidebook in (content.guidebooks || [])"
        :key="guidebook.url"
        class="panelItem guidebook"
      >
        <a :href="guidebook.url" download target="_blank">
          <SanityImage v-if="guidebook.image"
            :asset="guidebook.image.asset"
            :alt="content.title"
            class="desktopImageHeading"
          />
        </a>
      </div>
    </div>
  </Container>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default: Object,
    },
  },
}
</script>

<style scoped>

.GuidebookShowcase {
  padding-top: var(--spacing-15);
  padding-bottom: var(--spacing-15);
}

.panelAppearance {
  background-color: var(--bg-color-pale-blue);
  border-radius: 10px;
  padding: var(--spacing-6);
}

.panelLayout {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  align-items: center;
  gap: var(--spacing-3) var(--spacing-6);
}

.title {
  font-weight: 600;
  font-size: var(--font-size-7);
  line-height: 1;
  color: var(--text-color-black);
}

.subtitle {
  font-weight: 400;
  font-size: var(--font-size-4);
  line-height: 1.4;
  color: var(--text-color-black);
}

.title + .subtitle {
  margin-top: var(--spacing-0);
}

.guidebook {
  transition: filter 0.2s;
}

.guidebook:hover {
  filter: brightness(0.97);
}


@media (--screen-sm) {

  .panelLayout {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (--screen-lg) {

  .panelAppearance {
    padding: var(--spacing-6) var(--spacing-12);
  }

  .panelLayout {
    display: flex;
    justify-content: space-between;
    gap: var(--spacing-10);
  }

  .blurb {
    flex-basis: 13rem;
  }

  .guidebook {
    flex-basis: 11rem;
  }
}
</style>
