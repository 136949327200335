<template>
  <div class="SectionTextBlock">
    <h2 v-if="heading" class="heading">{{ heading }}</h2>
    <p v-if="overview" class="overview constrainedWidthText">{{ overview }}</p>
    <div v-if="body" class="body constrainedWidthText">
      <PortableText :value="body" />
    </div>
    <div v-if="cta && cta.text" class="ctaWrapper">
      <CtaLink v-bind="cta" />
    </div>
    <div v-if="ctas" class="ctaWrapper">
      <div v-for="cta in ctas" :key="cta._key" class="cta">
        <CtaLink v-bind="cta" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      default: '',
    },
    overview: {
      type: String,
    },
    body: {
      type: Array,
    },
    cta: {
      type: Object,
    },
    ctas: {
      type: Array,
    },
  },
}
</script>

<style scoped>
.SectionTextBlock {
  text-align: center;
}
.heading {
  font-size: var(--font-size-10);
  line-height: 1.3;
}
.heading, .overview {
  font-weight: 600;
  color: var(--text-color-black);
}
.constrainedWidthText {
  max-width: 58rem;
  margin-left: auto;
  margin-right: auto;
}
.body {
  font-weight: 400;
  color: var(--text-color-dark);
}
.overview, .body {
  font-size: var(--font-size-4);
  line-height: 1.7;
}
.heading + .overview {
  margin-top: var(--spacing-0);
}
.heading + .body {
  margin-top: var(--spacing-7);
}
.overview + .body {
  margin-top: var(--spacing-4);
}
.overview + .ctaWrapper {
  margin-top: var(--spacing-2);
}
.body + .ctaWrapper {
  margin-top: var(--spacing-7);
}
.cta + .cta {
  margin-top: var(--spacing-5);
  display: block;
}
</style>
