<template>
  <Container tagName="section" class="ResourceCards">
    <h2 class="heading">{{ content.heading }}</h2>
    <div v-if="content.resources" class="cardsLineup">
      <ResourceCard v-for="resource, index in content.resources"
        :key="index"
        :content="resource"
        class="card"
      />
    </div>
  </Container>
</template>

<script>
import ResourceCard from './ResourceCard.vue'

export default {
  components: {
    ResourceCard,
  },
  props: {
    content: {
      type: Object,
      default: Object,
    },
  },
}
</script>

<style scoped>

.ResourceCards {
  padding-top: var(--spacing-15);
  padding-bottom: var(--spacing-15);
  background-color: var(--bg-color-white);
}
.heading {
  font-weight: 600;
  font-size: var(--font-size-8);
  line-height: 1.3;
  color: var(--text-color-black);
}
.heading + .cardsLineup {
  margin-top: var(--spacing-7);
}
.card + .card {
  margin-top: var(--spacing-5);
}

@media (--screen-lg) {

  .cardsLineup {
    display: flex;
    column-gap: var(--spacing-5);
  }
  .card {
    flex-basis: 25%;
  }
  .card + .card {
    margin-top: 0;
  }
}
</style>
