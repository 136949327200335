<template>
  <div class="LogoStripScrolling">
    <div v-if="content.caption" class="caption">{{ content.caption }}</div>
    <SanityImageBackground v-if="content.scrollingLogos"
      :asset="content.scrollingLogos.asset"
      class="scrollingLogos"
      :style="cssProps"
    />
  </div>
</template>

<script>

// Extract the image dimensions from the image id.
// For example, if imageId is 'image-8907715eb86be773b8a2564885e96edd3bb08a89-2560x1440-png' then the
// dimensions will be { width: 2560, height: 1440 }.
function imageDimensions(imageId) {
  const matchData = imageId.match(/^image-[0-9a-f]{40}-(?<width>\d+)x(?<height>\d+)-\w+$/)
  if (matchData === null) {
    throw `Invalid Sanity image id '${imageId}'`
  }
  return matchData.groups;
}

const nominalHeight = 40;  // How many pixels high we want the logos image to be
const scrollSpeed = 25;    // How many pixels/second we want to scroll the logos at

export default {
  props: {
    content: Object,
  },
  computed: {
    cssProps() {
      if (this.content?.scrollingLogos?.asset) {
        const { width, height } = imageDimensions(this.content.scrollingLogos.asset._ref)
        const scaledWidth = Math.round(width * nominalHeight / height)
        const duration = Math.round(scaledWidth / scrollSpeed);
        return {
          '--width': `${scaledWidth}px`,
          '--height': `${nominalHeight}px`,
          '--duration': `${duration}s`,
        }
      }
    },
  },
}
</script>

<style scoped>

.LogoStripScrolling {
  padding-top: var(--spacing-8);
  padding-bottom: var(--spacing-8);
}

.caption {
  font-weight: 400;
  font-size: var(--font-size-2);
  line-height: 1.3;
  text-align: center;
  color: #9C9C9C;
}

@keyframes logoStripScrollingLogos {
  from {
    background-position-x: 0;
  }
  to {
    background-position-x: var(--width);
  }
}

.scrollingLogos {
  margin-top: var(--spacing-7);
  height: var(--height);
  background-size: var(--width) var(--height) !important;
  animation: logoStripScrollingLogos var(--duration) linear infinite reverse;
}
</style>
