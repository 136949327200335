<template>
  <section class="SubsectionWithImage">
    <div class="image" :class="{ narrow: narrowImage }">
      <SanityImage v-if="content.image" :asset="content.image.asset" />
    </div>
    <div class="text">
      <div class="textLengthConstraint">
        <p v-if="content.label" class="label">{{ content.label }}</p>
        <h3 class="heading">{{ content.heading }}</h3>
        <div class="body">
          <PortableText v-if="content.body" :value="content.body" />
        </div>
        <CtaLink v-for="cta in content.ctas" :key="cta.text" v-bind="cta" class="cta" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    narrowImage() {
      if (this.content.presentationOptions) {
        return this.content.presentationOptions.imageWidth == 'narrow'
      }
    },
  },
}
</script>

<style scoped>
.label {
  font-weight: 600;
  font-size: var(--font-size-2);
  text-transform: uppercase;
  color: var(--brand-color);
}
.heading {
  font-weight: 600;
  font-size: var(--font-size-8);
  line-height: 1.3;
  color: var(--text-color-black);
}
.body {
  font-weight: 400;
  font-size: var(--font-size-3);
  line-height: 1.6;
  color: var(--text-color-dark);
}
.label + .heading {
  margin-top: var(--spacing-0);
}
.heading + .body {
  margin-top: var(--spacing-3);
}
.body + .cta {
  margin-top: var(--spacing-8);
}
.cta + .cta {
  margin-top: var(--spacing-5);
  display: block;
}
.image + .text {
  margin-top: var(--spacing-12);
}

.textLengthConstraint .CtaLink {
  display: block;
}

@media (--screen-lg) {
  .SubsectionWithImage {
    display: flex;
    column-gap: var(--spacing-6);
    align-items: center;
  }
  .SubsectionWithImage:nth-child(2n) {
    flex-direction: row-reverse;
  }
  .image + .text {
    margin-top: 0;
  }
  .text, .image {
    flex-basis: 50%;
  }
  .image.narrow {
    padding-right: 10%;
  }
}
</style>
